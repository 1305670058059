import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import type { ApiModel } from '@composable-api/api.model'
import { BannerModel } from '../models/banner.model'

class BannersApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }
}

export default function useBannersApiService() {
    return new BannersApiReactiveService({
        endpoint: '/banners',
    }, BannerModel)
}
